import { PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { PortalProgressionFragment } from '../../../graphql/generated';
import { useFeature } from '../../../utils/feature-flags';
import { AnyObject } from '../../hooks/use-record-state';
import { useFormContext } from '../form-data-provider';
import {
  HandleSetPortalProgressionOptions,
  StepWithPath,
} from '../step-navigation';
import { useStepsStore } from '../step-navigation/steps-store-provider';
import { getStepIdentifier } from '../step-navigation/utils/get-step-identifier';

interface PortalProgressionHandlerProps<TFormData extends AnyObject> {
  initialPortalProgression?: PortalProgressionFragment | null;
  onSetPortalProgression?: ({
    currentStepIdentifier,
    formData,
    lastCompletedStepNumber,
  }: HandleSetPortalProgressionOptions<TFormData>) => Promise<void>;
  steps: StepWithPath<TFormData>[];
  stepsUrlBase: string;
}

export const PortalProgressionHandler = <TFormData extends AnyObject>({
  children,
  initialPortalProgression,
  onSetPortalProgression,
  steps,
  stepsUrlBase,
}: PropsWithChildren<Readonly<PortalProgressionHandlerProps<TFormData>>>) => {
  const isAdminPortalPersistenceEnabled = useFeature('adminPortalPersistence');
  const navigate = useNavigate();

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [portalProgression, setPortalProgression] = useState<
    PortalProgressionFragment | undefined | null
  >(initialPortalProgression);

  const { formData, setFormData } = useFormContext();
  const { currentStepNumber } = useStepsStore();

  const persistPortalProgression = useDebouncedCallback(
    (currentStepIdentifier, formData) => {
      if (!onSetPortalProgression) {
        return;
      }

      const currentStep = steps[currentStepNumber - 1];

      const lastCompletedStepNumber = getLastCompletedStepNumber(
        portalProgression,
        currentStepNumber,
      );

      void onSetPortalProgression({
        currentStep,
        currentStepIdentifier,
        formData,
        lastCompletedStepNumber,
        setPortalProgression,
      });
    },
    2000,
    {
      leading: true,
    },
  );

  useEffect(
    () => {
      if (!isAdminPortalPersistenceEnabled) {
        return;
      }

      // Avoid re-saving the portal progression on initial load
      if (isInitialLoad) {
        setIsInitialLoad(false);

        return;
      }

      const currentStep = steps[currentStepNumber - 1];

      if (!currentStep) {
        return;
      }

      const currentStepIdentifier = getStepIdentifier(
        currentStepNumber,
        currentStep.title,
      );

      if (!currentStepIdentifier) {
        return;
      }

      void persistPortalProgression(currentStepIdentifier, formData);
    },
    // Excluding isInitialLoad from the dependency array to avoid setting the progression
    // when initial load is set to false
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentStepNumber,
      formData,
      isAdminPortalPersistenceEnabled,
      persistPortalProgression,
      steps,
    ],
  );

  useEffect(() => {
    if (isInitialLoad) {
      if (!isAdminPortalPersistenceEnabled || !portalProgression) {
        return;
      }

      const currentStepIndex = steps.findIndex(
        (step) =>
          step.stepIdentifier === portalProgression.currentStepIdentifier,
      );

      if (currentStepIndex === -1) {
        navigate(`${stepsUrlBase}/1`, { replace: true });

        return;
      }

      setFormData(portalProgression.formData);

      const currentStepNumber = currentStepIndex + 1;

      navigate(`${stepsUrlBase}/${currentStepNumber}`, { replace: true });
    }
  }, [
    isAdminPortalPersistenceEnabled,
    isInitialLoad,
    navigate,
    portalProgression,
    setFormData,
    steps,
    stepsUrlBase,
  ]);

  return <>{children}</>;
};

const getLastCompletedStepNumber = (
  portalProgression: PortalProgressionFragment | null | undefined,
  currentStepNumber: number,
): number => {
  if (!portalProgression) {
    return currentStepNumber;
  }

  if (currentStepNumber > portalProgression.lastCompletedStepNumber) {
    return currentStepNumber;
  }

  return portalProgression.lastCompletedStepNumber;
};
