import jwt from 'jsonwebtoken';
import { graphql } from './graphql';
import { QueryParams } from './use-query-string';

const isDate = (date: string): boolean =>
  new Date(date).toString() !== 'Invalid Date' &&
  !isNaN(new Date(date).getTime());

export const getTelemetryData = (
  queryParams: QueryParams,
): { started_at: number; legacy: boolean } | void => {
  const legacy = !Boolean(queryParams.token);
  let startedAt: number | undefined;

  if (queryParams.started_at) {
    startedAt = Number(queryParams.started_at);
  } else if (queryParams.token) {
    try {
      const token = jwt.decode(queryParams.token);

      if (
        typeof token === 'object' &&
        token?.started_at &&
        isDate(token?.started_at)
      ) {
        startedAt = Number(token.started_at);
      }
    } catch (error) {
      return;
    }
  }

  if (startedAt) {
    return { legacy, started_at: startedAt };
  }
};

const getTelemetryHooks = (queryParams: QueryParams) => {
  const telemetryData = getTelemetryData(queryParams);

  if (telemetryData) {
    void graphql().Ready(telemetryData);
  }

  const loaded = () => {
    if (telemetryData) {
      void graphql().Loaded(telemetryData);
    }
  };

  const ready = () => {
    if (telemetryData) {
      void graphql().Ready(telemetryData);
    }
  };

  return { loaded, ready };
};

export const withTelemetry = async <T>(
  queryParams: QueryParams,
  thunk: () => Promise<T>,
) => {
  const { loaded, ready } = getTelemetryHooks(queryParams);

  loaded();

  const response = await thunk();

  ready();

  return response;
};
